import { onMounted, reactive, ref, watch } from "vue";
import dayjs from "dayjs";
import Handsontable from "handsontable";

import { getWaring, showError, showSuccess, showWarning } from "@/libs/notification";
import { exportExcel } from "@/libs/export";
import { orderService } from "@/services/order";
import { DATE_FORMAT } from "@/configs/constants";
import { Order, OrderPayload, OrderSearchForm, OrderStatus, TableColumn } from "@/configs/orders";
import { helper as $h } from "@/utils/helper";
import { columnConfigs, columnWidths, initialStatus, signColumnConfigs, signColumnWidths } from "./columns";
import { Pagination } from "@/configs/common";
import { ExportField } from "@/configs/jtTypes";
import { useRouter } from "vue-router";

export const useOrder: any = () => {
  const orderStatus = ref<OrderStatus[]>(initialStatus);
  const selectedOrder = ref<Order>({});
  const loading = ref(false);
  const hot = ref();
  const router = useRouter();
  const tableRef = ref<HTMLElement>();
  const today = dayjs().format(DATE_FORMAT);
  const formData = reactive(<OrderSearchForm>{
    dateRange: `${today} - ${today}`,
  });
  const pagination = ref<Pagination>({
    page: 1,
    pageSize: 20,
  });
  const orders = ref<any[]>([]);
  const columns = ref<TableColumn[]>(columnConfigs);
  const widths = ref<number[]>(columnWidths);
  const sorter = reactive<{ sortField?: string; sortOrder?: string }>({});
  const sortFields: { [key: string]: string } = {
    "4": "excel_index",
    "5": "print_number",
    "6": "custom_code",
  };

  const setSorter = (config: { column: number; sortOrder: string }) => {
    sorter.sortField = sortFields[config.column.toString()];
    sorter.sortOrder = config.sortOrder;
    getOrders(config);
  };

  // Get Orders
  const preparePayload = (): OrderPayload => {
    const [startDate, endDate] = $h.dateRangeToDate(formData.dateRange);
    return {
      query: formData.query || undefined,
      page: pagination.value.page || 1,
      pageSize: pagination.value.pageSize || 20,
      sortField: sorter.sortField || undefined,
      sortOrder: sorter.sortOrder || undefined,
      status: formData.status,
      orderBy: "excel_index",
      startDate,
      endDate,
    };
  };

  const getOrders = async (config = {}) => {
    loading.value = true;
    hot.value.loadData([]);
    try {
      const result = (await orderService.getOrders(preparePayload())).result;
      orders.value = result.orders;
      pagination.value.total = result.total;
      hot.value.loadData(orders.value);
      const columnSortPlugin = hot.value.getPlugin("columnSorting");
      columnSortPlugin.setSortConfig(config);
      hot.value.render();
    } catch (error) {
      showError(error.response ? error.response.data.error : "Co loi xay ra");
    } finally {
      loading.value = false;
    }
  };

  const changeStatus = (status: string) => {
    formData.status = status;
    pagination.value.page = 1;
    if (status === "SIGN") {
      columns.value = signColumnConfigs;
      widths.value = signColumnWidths;
    } else {
      columns.value = columnConfigs;
      widths.value = columnWidths;
    }
    hot.value.updateSettings({
      columns: columns.value,
      colWidths: widths.value,
    });
    getOrders();
  };

  const getStatusCount = async () => {
    loading.value = true;
    const payload = preparePayload();
    delete payload.status;
    try {
      const status = (await orderService.getStatusCount(payload)).result.summary;
      orderStatus.value = orderStatus.value.map((item) => {
        let count = 0;
        if (item.status === undefined) count = status.TOTAL || 0;
        else count = status[item.status] || 0;
        return {
          display: item.display,
          status: item.status,
          count,
        };
      });
    } catch (error) {
      showError(error.response ? error.response.data.error : "Co loi xay ra");
    } finally {
      loading.value = false;
    }
  };

  const search = () => {
    getStatusCount();
    getOrders();
  };

  // Function buttons
  async function cancelOrder(ids: string[]) {
    const toast = getWaring("Đang xử lý...");
    toast.showToast();
    try {
      await orderService.cancelOrder(ids);
      showSuccess("Hủy đơn thành công");
      search();
    } catch (err) {
      showError("Có lỗi xảy ra");
    } finally {
      toast.hideToast();
    }
  }

  const onCancelOrder = () => {
    const selectedRows = orders.value.filter((item) => item.select);
    if (!selectedRows || selectedRows.length === 0) {
      showWarning("Vui lòng chọn đơn hủy");
      return;
    }
    for (const item of selectedRows) {
      if (item.status !== "PENDING") {
        showWarning("Chỉ có thể huỷ đơn ở trạng thái đang chờ lấy hàng");
        return;
      }
    }
    cancelOrder(selectedRows.map((item) => item.id));
    orders.value.forEach((e) => (e.select = false));
  };

  const exportSelected = (columns: ExportField[]) => {
    const selectedRows = orders.value.filter((item) => item.select);
    if (!selectedRows || selectedRows.length === 0) {
      showWarning("Vui lòng chọn đơn cần truy xuất");
      return;
    }
    exportExcel(selectedRows, columns);
    orders.value.forEach((e) => (e.select = false));
  };

  const exportAll = async (columns: ExportField[]) => {
    loading.value = true;
    try {
      const payload = { ...preparePayload(), pageSize: 10000 };
      const result = (await orderService.getOrders(payload)).result;
      exportExcel(result.orders, columns);
    } catch (error) {
      showError(error.response ? error.response.data.error : "Co loi xay ra");
    } finally {
      loading.value = false;
    }
  };

  const printOrder = async (template: "J_T" | "A4" | "A5" | "A5_Old") => {
    const selectedRows = orders.value.filter((item) => item.select);
    if (!selectedRows || selectedRows.length === 0) {
      showWarning("Vui lòng chọn đơn in");
      return;
    }
    for (const item of selectedRows) {
      if (item.status !== "PENDING") {
        showWarning("Chỉ có thể in đơn ở trạng thái đang chờ lấy hàng");
        return;
      }
    }
    orders.value.forEach((e) => (e.select = false));
    try {
      loading.value = true;
      const data = await orderService.print(
        selectedRows.map((item) => item.id),
        template,
        sorter.sortField || "excel_index"
      );
      if (data?.result?.printUrl) {
        window.open(data?.result?.printUrl, "_blank");
      }
    } catch (error) {
      showError(error);
    } finally {
      loading.value = false;
    }
  };

  // Pagination
  const handlePaginationChange = (newVal: Pagination) => {
    pagination.value = { ...pagination.value, ...newVal };
    getOrders();
  };

  // Handson Table
  const isChecked = () => {
    const find = orders.value.find((item) => !item.select);
    return !find;
  };

  const colHeaders = (col: number) => {
    let txt;

    switch (col) {
      case 0:
        txt = "<input type='checkbox' class='checker' ";
        txt += isChecked() ? 'checked="checked"' : "";
        txt += ">";

        return txt;

      default:
        return columns.value[col].header || "";
    }
  };

  const initHandson = () => {
    if (tableRef.value) {
      hot.value = new Handsontable(tableRef.value, {
        rowHeaders: true,
        colHeaders: colHeaders,
        columns: columns.value,
        afterOnCellMouseDown: function (e, coords) {
          if (coords.col === 2) {
            const billCode = hot.value.getDataAtRow(coords.row)[3];
            const find = orders.value.find((item) => item.billCode === billCode);
            if (find) {
              selectedOrder.value = find;
              cash("#order-edit-form").modal("show");
            }
          }
          if (coords.col === 1) {
            const billCode = hot.value.getDataAtRow(coords.row)[3];
            const propOrder = orders.value.find((item) => item.billCode === billCode);
            if (propOrder.status === "CANCEL_ORDER") {
              router.push({
                name: "order-create",
                params: {
                  ...propOrder,
                },
              });
            } else {
              router.push({
                name: "order-tracking",
                query: {
                  billCode: billCode,
                },
              });
            }
          }
        },
        beforeColumnSort: function (prev, targetConfigs) {
          const config = targetConfigs[0];
          if ([4, 5, 6].includes(config.column)) {
            setSorter(config);
          }
          return false;
        },
        columnSorting: true,
        colWidths: widths.value,
        rowHeights: 35,
        stretchH: "all",
        className: "htMiddle handsongray",
        fixedColumnsLeft: 4,
        licenseKey: "non-commercial-and-evaluation",
        width: "100%",
        height: "100%",
      });
      Handsontable.dom.addEvent(tableRef.value, "mousedown", function (event: any) {
        if (event.target.nodeName === "INPUT" && event.target.className === "checker") {
          event.stopPropagation();
        }
      });

      Handsontable.dom.addEvent(tableRef.value, "mouseup", function (event: any) {
        if (event.target.nodeName === "INPUT" && event.target.className === "checker") {
          const check = isChecked();
          orders.value.forEach((e) => (e.select = !check));
          hot.value.render();
        }
      });
    }
  };

  watch(
    () => formData.query,
    (val: any) => {
      formData.query = val.replace(" ", ",").trim();
    }
  );

  onMounted(() => {
    initHandson();
    getOrders();
    getStatusCount();
  });

  return {
    tableRef,
    changeStatus,
    pagination,
    search,
    orderStatus,
    handlePaginationChange,
    formData,
    loading,
    columns,
    orders,
    getOrders,
    onCancelOrder,
    exportSelected,
    exportAll,
    printOrder,
    selectedOrder,
  };
};
