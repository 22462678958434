<template>
  <div class="mt-2">
    <!-- BEGIN: Filter -->
    <div class="intro-y box px-5 pt-2">
      <div
        class="grid grid-cols-12 border-b border-gray-200 dark:border-dark-5 pb-2 -mx-5 gap-2 pl-2"
      >
        <div class="col-span-5">
          <input
            v-model="formData.query"
            type="text"
            class="input w-full border"
            placeholder="Nhập số theo dõi, số điện thoại hoặc mã khách hàng"
          />
        </div>
        <div class="col-span-3">
          <Litepicker
            v-model="formData.dateRange"
            :options="{
              singleMode: false,
              numberOfColumns: 2,
              numberOfMonths: 2,
              showWeekNumbers: true,
              dropdowns: {
                minYear: 1990,
                maxYear: null,
                months: true,
                years: true,
              },
            }"
            class="input w-full border block mx-auto"
          />
        </div>
        <div class="col-span-2">
          <button
            type="button"
            class="button bg-theme-1 flex justify-center text-white"
            @click="search"
          >
            <LoadingIcon color="white" class="w-4 h-4 mr-1" v-if="loading" />
            <SearchIcon class="w-4 h-4 mr-1" v-else /> Tìm kiếm
          </button>
        </div>
      </div>
      <div
        class="nav-tabs flex flex-col sm:flex-row justify-center lg:justify-start"
      >
        <a
          v-for="status in orderStatus"
          :key="status.status"
          data-toggle="tab"
          href="javascript:;"
          class="py-4 sm:mr-8"
          style="font-size: 12px"
          :class="`${status.status === undefined ? 'active' : ''}`"
          @click="changeStatus(status.status)"
        >
          {{ `${status.display}(${status.count})` }}
        </a>
      </div>
    </div>
    <!-- END: Filter -->
    <!-- BEGIN: Function Button -->
    <div class="intro-y flex flex-col-reverse sm:flex-row items-center mt-4">
      <div class="w-full sm:w-auto flex">
        <button
          class="button button--sm text-white bg-theme-4 items-center justify-center flex shadow-md mr-2"
          @click="showExportModal"
        >
          <LoadingIcon color="white" class="w-4 h-4 mr-1" v-if="loading" />
          <FileTextIcon class="w-4 h-4 mr-1" v-else /> Truy xuất
        </button>
        <div id="test" class="dropdown">
          <button
            class="dropdown-toggle button button--sm justify-center items-center flex text-gray-600 bg-white shadow-md mr-2"
          >
            <LoadingIcon color="grey" class="w-4 h-4 mr-1" v-if="loading" />
            <PrinterIcon class="w-4 h-4 mr-1" v-else /> In đơn đặt
          </button>
          <div class="dropdown-box w-20">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2">
              <a
                href="javascript:;"
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="printOrder('J_T')"
              >
                Chuẩn
              </a>
              <a
                href="javascript:;"
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="printOrder('A5_Old')"
              >
                A5 (Cũ)
              </a>
              <a
                href="javascript:;"
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="printOrder('80x80')"
              >
                80x80
              </a>
            </div>
          </div>
        </div>
        <button
          class="button button--sm text-white flex items-center justify-center bg-theme-1 shadow-md mr-2"
          @click="onCancelOrder"
        >
          <LoadingIcon color="white" class="w-4 h-4 mr-1" v-if="loading" />
          <XCircleIcon class="w-4 h-4 mr-1" v-else /> Huỷ đơn đặt
        </button>
      </div>
    </div>
    <div class="box mt-4 handson z-0">
      <div
        id="handson"
        ref="tableRef"
        class="hot handsontable htRowHeaders htColumnHeaders"
        style="height: 100%; overflow: hidden; width: 100%"
        data-originalstyle="height: 100%; overflow: hidden; width: 100%;"
      ></div>
    </div>
    <!-- End: Handson Table -->
    <Pagination
      :pagination="pagination"
      @handlePaginationChange="handlePaginationChange"
    />
  </div>
  <ExportModal @export-all="exportAll" @export-selected="exportSelected" />
  <OrderEditModal :order="selectedOrder" :readonly="true" />
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent } from "vue";
import "handsontable/dist/handsontable.full.css";

import { useOrder } from "./useOrder";

const Pagination = defineAsyncComponent(
  () => import("@/components/pagination/Main.vue")
);
const ExportModal = defineAsyncComponent(
  () => import("@/components/export-modal/Main.vue")
);
const OrderEditModal = defineAsyncComponent(
  () => import("@/components/order-edit-modal/Main.vue")
);

export default defineComponent({
  components: {
    Pagination,
    ExportModal,
    OrderEditModal,
  },
  setup() {
    const {
      tableRef,
      orderStatus,
      formData,
      orders,
      handlePaginationChange,
      search,
      pagination,
      changeStatus,
      columns,
      loading,
      onCancelOrder,
      exportSelected,
      exportAll,
      printOrder,
      selectedOrder,
    } = useOrder();
    return {
      tableRef,
      orderStatus,
      formData,
      handlePaginationChange,
      columns,
      pagination,
      orders,
      changeStatus,
      loading,
      search,
      onCancelOrder,
      exportSelected,
      exportAll,
      selectedOrder,
      printOrder,
    };
  },
  methods: {
    showExportModal() {
      cash("#export-modal").modal("show");
    },
  },
});
</script>
