
import { defineAsyncComponent, defineComponent } from "vue";
import "handsontable/dist/handsontable.full.css";

import { useOrder } from "./useOrder";

const Pagination = defineAsyncComponent(
  () => import("@/components/pagination/Main.vue")
);
const ExportModal = defineAsyncComponent(
  () => import("@/components/export-modal/Main.vue")
);
const OrderEditModal = defineAsyncComponent(
  () => import("@/components/order-edit-modal/Main.vue")
);

export default defineComponent({
  components: {
    Pagination,
    ExportModal,
    OrderEditModal,
  },
  setup() {
    const {
      tableRef,
      orderStatus,
      formData,
      orders,
      handlePaginationChange,
      search,
      pagination,
      changeStatus,
      columns,
      loading,
      onCancelOrder,
      exportSelected,
      exportAll,
      printOrder,
      selectedOrder,
    } = useOrder();
    return {
      tableRef,
      orderStatus,
      formData,
      handlePaginationChange,
      columns,
      pagination,
      orders,
      changeStatus,
      loading,
      search,
      onCancelOrder,
      exportSelected,
      exportAll,
      selectedOrder,
      printOrder,
    };
  },
  methods: {
    showExportModal() {
      cash("#export-modal").modal("show");
    },
  },
});
